<script>
  import Total4 from "../bets/Total4.svelte"
  import Total5 from "../bets/Total5.svelte"
  import Total6 from "../bets/Total6.svelte"
  import Total7 from "../bets/Total7.svelte"
  import Total8 from "../bets/Total8.svelte"
  import Total9 from "../bets/Total9.svelte"
  import Total10 from "../bets/Total10.svelte"
  import Total11 from "../bets/Total11.svelte"
  import Total12 from "../bets/Total12.svelte"
  import Total13 from "../bets/Total13.svelte"
  import Total14 from "../bets/Total14.svelte"
  import Total15 from "../bets/Total15.svelte"
  import Total16 from "../bets/Total16.svelte"
  import Total17 from "../bets/Total17.svelte"
</script>

<div class="flex w-full">
  <div class="w-1/14 pr-1"><Total4 /></div>
  <div class="w-1/14 px-1"><Total5 /></div>
  <div class="w-1/14 px-1"><Total6 /></div>
  <div class="w-1/14 px-1"><Total7 /></div>
  <div class="w-1/14 px-1"><Total8 /></div>
  <div class="w-1/14 px-1"><Total9 /></div>
  <div class="w-1/14 px-1"><Total10 /></div>
  <div class="w-1/14 px-1"><Total11 /></div>
  <div class="w-1/14 px-1"><Total12 /></div>
  <div class="w-1/14 px-1"><Total13 /></div>
  <div class="w-1/14 px-1"><Total14 /></div>
  <div class="w-1/14 px-1"><Total15 /></div>
  <div class="w-1/14 px-1"><Total16 /></div>
  <div class="w-1/14 pl-1"><Total17 /></div>
</div>
