<script>
  import DoublesTriplesSmall from "../subgrids/DoublesTriplesSmall.svelte"
  import AnyTriples from "../bets/AnyTriples.svelte"
  import DoublesTriplesBig from "../subgrids/DoublesTriplesBig.svelte"
</script>

<div class="grid grid-cols-3 gap-x-2">
  <DoublesTriplesSmall />
  <AnyTriples />
  <DoublesTriplesBig />
</div>
