<script>
  import Single1 from "../bets/Single1.svelte"
  import Single2 from "../bets/Single2.svelte"
  import Single3 from "../bets/Single3.svelte"
  import Single4 from "../bets/Single4.svelte"
  import Single5 from "../bets/Single5.svelte"
  import Single6 from "../bets/Single6.svelte"
</script>

<div class="flex w-full">
  <div class="w-1/6 pr-1"><Single1 /></div>
  <div class="w-1/6 px-1"><Single2 /></div>
  <div class="w-1/6 px-1"><Single3 /></div>
  <div class="w-1/6 px-1"><Single4 /></div>
  <div class="w-1/6 px-1"><Single5 /></div>
  <div class="w-1/6 pl-1"><Single6 /></div>
</div>
