<script>
  import Combo12 from "../bets/Combo12.svelte"
  import Combo13 from "../bets/Combo13.svelte"
  import Combo14 from "../bets/Combo14.svelte"
  import Combo15 from "../bets/Combo15.svelte"
  import Combo16 from "../bets/Combo16.svelte"
  import Combo23 from "../bets/Combo23.svelte"
  import Combo24 from "../bets/Combo24.svelte"
  import Combo25 from "../bets/Combo25.svelte"
  import Combo26 from "../bets/Combo26.svelte"
  import Combo34 from "../bets/Combo34.svelte"
  import Combo35 from "../bets/Combo35.svelte"
  import Combo36 from "../bets/Combo36.svelte"
  import Combo45 from "../bets/Combo45.svelte"
  import Combo46 from "../bets/Combo46.svelte"
  import Combo56 from "../bets/Combo56.svelte"
</script>

<div class="flex w-full">
  <div class="w-1/15 pr-1"><Combo12 /></div>
  <div class="w-1/15 px-1"><Combo13 /></div>
  <div class="w-1/15 px-1"><Combo14 /></div>
  <div class="w-1/15 px-1"><Combo15 /></div>
  <div class="w-1/15 px-1"><Combo16 /></div>
  <div class="w-1/15 px-1"><Combo23 /></div>
  <div class="w-1/15 px-1"><Combo24 /></div>
  <div class="w-1/15 px-1"><Combo25 /></div>
  <div class="w-1/15 px-1"><Combo26 /></div>
  <div class="w-1/15 px-1"><Combo34 /></div>
  <div class="w-1/15 px-1"><Combo35 /></div>
  <div class="w-1/15 px-1"><Combo36 /></div>
  <div class="w-1/15 px-1"><Combo45 /></div>
  <div class="w-1/15 px-1"><Combo46 /></div>
  <div class="w-1/15 pl-1"><Combo56 /></div>
</div>
